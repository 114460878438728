<template>
  <div>
    <div
      id="view-ticket-details-modal"
      class="modal"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="update">
            <div class="modal-header">
              <h5 class="modal-title">Ticket Evaluation Report</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h6 class="mb-3">
                Ticket No: <strong>{{ ticket.id }}</strong>
              </h6>
              <div class="form-group">
                <label for="">Unit/Section</label>
                <select
                  class="form-control"
                  v-model="ticket.unit"
                  disabled
                  required
                >
                  <option value="" selected disabled hidden>Choose</option>
                  <option
                    v-for="unit in units"
                    :key="unit.id"
                    :value="unit.name"
                  >
                    {{ unit.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="">Service Type</label>
                <select
                  class="form-control"
                  v-model="ticket.type"
                  disabled
                  required
                >
                  <option value="" selected disabled hidden>Choose</option>
                  <option
                    v-for="type in types"
                    :key="type.id"
                    :value="type.name"
                  >
                    {{ type.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="">Description</label>
                <textarea
                  v-model="ticket.description"
                  name=""
                  class="form-control"
                  id=""
                  rows="5"
                  disabled
                  required
                ></textarea>
              </div>

              <div class="form-group">
                <label for="">Issues found</label>
                <textarea
                  v-model="ticket.diagnosis"
                  name=""
                  class="form-control"
                  id=""
                  rows="3"
                  :disabled="ticket.status === 'Closed'"
                  required
                ></textarea>
              </div>

              <div class="form-group">
                <label for="">Actions taken</label>
                <textarea
                  v-model="ticket.actions_taken"
                  name=""
                  class="form-control"
                  id=""
                  rows="3"
                  required
                  :disabled="ticket.status === 'Closed'"
                ></textarea>
              </div>

              <div class="form-group">
                <label for="">Feedback/Comment</label>
                <textarea
                  v-model="ticket.feedback"
                  name=""
                  class="form-control"
                  id=""
                  rows="3"
                  required
                  disabled
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                v-if="ticket.status !== 'Closed'"
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                {{ isLoading ? "Submitting..." : "Update" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateTicketFormModal",
  data() {
    return {
      ticket: new Form({
        id: "",
        type: "",
        unit: "",
        status: "",
        assigned_to: "",
        description: "",
        diagnosis: "",
        actions_taken: "",
        feedback: "",
      }),
      isLoading: false,
      units: [
        { id: 1, name: "Accounting" },
        { id: 2, name: "Cashier" },
        { id: 3, name: "CMSP" },
        { id: 4, name: "General Services" },
        { id: 5, name: "Human Resources" },
        { id: 6, name: "Records" },
        { id: 7, name: "Technical" },
        { id: 8, name: "UniFAST" },
        { id: 9, name: "Admin" },
      ],
      types: [
        { id: 8, name: "Air Conditioning System" },
        { id: 9, name: "Building Structure" },
        { id: 5, name: "CCMIS System Glitch/Bug" },
        { id: 10, name: "Electrical" },
        { id: 3, name: "Hardware Installation" },
        { id: 4, name: "Maintenance" },
        { id: 11, name: "Plumbing" },
        { id: 1, name: "Troubleshooting" },
        { id: 2, name: "Software Installation" },
        { id: 7, name: "Repairs" },
        { id: 6, name: "Others" },
      ],
    };
  },
  methods: {
    open(ticket) {
      this.ticket.fill(ticket);
      $("#view-ticket-details-modal").modal("toggle");
    },
    update() {
      this.isLoading = true;
      axios
        .put("api/tickets/" + this.ticket.id, this.ticket)
        .then((res) => {
          toastr.success(res.data.message, "Information");
          this.$emit("updateTable");
          $("#view-ticket-details-modal").modal("toggle");
          this.form.reset();
        })
        .catch((error) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
