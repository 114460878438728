<template>
  <div>
    <div class="dropleft">
      <button
        id="notifications-dropdown"
        class="btn"
        data-toggle="dropdown"
        type="button"
      >
        <i class="bi bi-bell"></i>
        <span
          v-if="unreadNotifications.length"
          class="badge badge-pill badge-danger"
          >{{ unreadNotifications.length }}</span
        >
      </button>
      <div class="py-0 dropdown-menu shadow-sm" style="min-width: 300px">
        <div class="px-3 py-2 bg-secondary font-weight-bold text-center">
          Notifications
        </div>
        <div class="">
          <div v-if="!unreadNotifications.length" class="p-3 text-center">
            <span>You have no unread notifications.</span>
          </div>
          <div
            v-for="notification in unreadNotifications"
            :key="notification.id"
            class="border-bottom py-2 px-3"
          >
            <p class="mb-0">
              {{ notification.data.message }}
            </p>
            <small class="text-muted">
              {{
                new Date(notification.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              }}</small
            >
          </div>
        </div>
        <div class="" v-show="unreadNotifications.length">
          <button
            class="btn btn-secondary w-100 rounded-0"
            @click="markAllAsRead"
            type="button"
          >
            Mark all as read
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      unreadNotifications: [],
    };
  },
  methods: {
    getNotifications() {
      axios.get("api/notifications").then((res) => {
        console.log(res);
        this.unreadNotifications = res.data.unreadNotifications;
        console.log(this.unreadNotifications);
      });
    },
    markAllAsRead() {
      $("#notifications-dropdown").dropdown("toggle");

      axios
        .post("api/notifications/mark-as-read")
        .then((res) => {
          this.unreadNotifications = [];
        })
        .catch((error) => {
          toastr.error("Unable to retrieve notifications.", "Server Error");
        });
    },
  },
  mounted() {
    this.getNotifications();
  },
};
</script>
