import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import store from './store/store';
import axios from 'axios';
import { Form } from 'vform';
import toastr from 'toastr';
import VueHtmlToPaper from 'vue-html-to-paper';
import regeneratorRuntime from "regenerator-runtime";
import { toastrOptions, printOptions } from './js/options'
import './js/filters';
import { checkNavigationGuards } from './middlewares/auth'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.use(VueHtmlToPaper, printOptions);

// global functions
window.axios = axios;
window.Form = Form;
window.toastr = toastr;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://api.chedcaraga.ph/';
toastr.options = toastrOptions;

axios.interceptors.response.use( (response) => response, (error) => {
  if(error.response && error.response.status === 401) {
    store.commit("setAuthentication", false);
    store.commit("setUser", null);
    router.push({ name: "Login"});
  }
 
 return Promise.reject(error);
 }
);

// perform navigation check for every route change
router.beforeEach(checkNavigationGuards)

new Vue({
    el: '#app',
    router,
    store,  
    template: '<App/>',
    components: {
        App
    }
})
    

