<template>
  <div>
    <div class="modal fade" data-backdrop="static" id="issue-serial-modal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <table class="table table-sm mt-2">
              <thead>
                <th>ID</th>
                <th>NAME</th>
                <th>HEI</th>
                <th>NAME</th>
                <th>AWARD YEAR</th>
              </thead>
              <tbody>
                <tr v-for="(student, index) in selected" :key="index">
                  <td>{{ student.id }}</td>
                  <td>
                    {{
                      `${student.last_name}, ${student.first_name} ${student.middle_name} `
                    }}
                    {{ student.extension_name }}
                  </td>
                  <td>{{ student.abbrev }}</td>
                  <td>{{ student.component }}</td>
                  <td>{{ student.award_year }}</td>
                </tr>
              </tbody>
            </table>

            <p>
              Total no. of students: <strong>{{ selected.length }}</strong>
            </p>

            <div class="form-check mt-4">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                v-model="checkbox"
              />
              <label class="form-check-label">
                All entries have been double-checked and confirmed to be
                correct.
              </label>
            </div>

            <div v-show="loader" class="mt-3">
              <div class="spinner-border spinner-border-sm mr-1"></div>
              Please wait. This may take a while. Do not refresh the page.
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button
              id="generate-serial-number-btn"
              class="btn btn-primary"
              @click.prevent="generateSerialNumber"
              :disabled="!checkbox"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IssueSerialModal",
  props: {
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      loader: false,
      checkbox: false,
      reloadSpinner: false,
    };
  },
  methods: {
    open() {
      $("#issue-serial-modal").modal("show");
      this.errors = [];
    },
    generateSerialNumber() {
      let btn = document.getElementById("generate-serial-number-btn");
      this.loader = true;
      btn.disabled = true;

      axios
        .put("api/nstp/admin/generate-serial-number", {
          selected: this.selected,
        })
        .then((res) => {
          this.loader = false;
          btn.disabled = false;
          this.$emit("emptySelected");
          this.$emit("getAllApplications");
          $("#issue-serial-modal").modal("hide");
        })
        .catch((err) => {
          this.errors = err.response.data;
          toastr.error(err.response.data.message, "Error");
          this.loader = false;
          btn.disabled = false;
        });
    },
  },
};
</script>
