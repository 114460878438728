<template>
  <div class="mx-auto">
    <div class="d-flex justify-content-between align-items-center px-3 mb-3">
      <div>
        <h5 class="mb-1">
          <i class="bi bi-headset mr-2"></i> Integrated Repair and Maintenance
          Support (IRMS) Services
        </h5>
        <p class="text-dark">
          Submit a support ticket for any ICT issues, facility maintenance,
          utility problems, and etc.
        </p>
      </div>
      <div>
        <CreateTicketFormModal
          @getTickets="getTickets(DEFAULT_URL, { loader: false })"
        ></CreateTicketFormModal>
      </div>
    </div>
    <CCard class="rounded shadow-sm">
      <CCardBody class="px-0">
        <div
          class="d-flex justify-content-between align-items-center mb-3 mt-2 px-4"
        >
          <div>
            <h5 class="mb-0 font-weight-bold">My Tickets</h5>
          </div>
        </div>

        <div
          class="d-flex flex-column flex-md-row justify-content-start justify-content-lg-between px-4 mb-3"
        >
          <div
            class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
          >
            <div class="form-inline pr-3">
              <div class="form-group">
                <label for="">No. of rows</label>
                <select
                  name=""
                  id=""
                  class="form-control form-control ml-0 ml-sm-2"
                  v-model="perPage"
                  @change="
                    getTickets(DEFAULT_URL, {
                      loader: false,
                    })
                  "
                >
                  <option
                    v-for="option in perPageOptions"
                    :key="option.id"
                    :value="option.value"
                  >
                    {{ option.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mr-3">
              <div
                class="d-none d-sm-block position-relative form-group has-search my-auto"
              >
                <span class="bi bi-search form-control-feedback"></span>
                <input
                  type="search"
                  class="form-control w-auto"
                  placeholder="Search"
                />
              </div>
            </div>
            <div class="py-2 p-sm-0">
              <div class="dropdown">
                <button
                  class="btn btn-outline-secondary mr-3 text-dark dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Bulk Actions
                  <span v-if="selected.length" class="mr-1">
                    ({{ selected.length }})</span
                  >
                  <span
                    v-if="actionLoader"
                    class="spinner-border spinner-border-sm"
                  ></span>
                </button>

                <button
                  class="btn btn-outline-secondary text-dark"
                  @click="
                    getTickets(DEFAULT_URL, {
                      loader: false,
                      tableLoader: false,
                    })
                  "
                >
                  <i class="bi bi-arrow-repeat"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="openCloseTicketModal"
                    >Close</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="forwardJobOrderReferrals"
                    >Forward to GSS</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex justify-content-right">
              <div class="my-auto mr-2">
                <span
                  >{{
                    `${pagination.from || 0}-${pagination.to || 0} of ${
                      pagination.total || 0
                    }`
                  }}
                </span>
              </div>
              <nav>
                <ul class="pagination mb-0">
                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.prev_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        getTickets(pagination.next_page_url, {
                          loader: false,
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-left"></i
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.next_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        getTickets(pagination.next_page_url, {
                          loader: false,
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-right"></i
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table text-nowrap">
            <thead>
              <tr>
                <th class="px-4">
                  <input
                    type="checkbox"
                    style="width: 15px; height: 15px"
                    v-model="checked"
                    @change="checked ? selectAll() : unselectAll()"
                    :disabled="
                      tickets.every((ticket) => {
                        return ticket.status === 'Closed';
                      })
                    "
                  />
                </th>
                <th scope="col" class="px-4">Ticket No.</th>
                <th scope="col" class="px-4">Unit/Section</th>
                <th scope="col" class="px-4">Request Type</th>

                <th scope="col" class="px-4">Created by</th>
                <th scope="col" class="px-4">Created at</th>
                <th scope="col" class="px-4">Status</th>
                <th scope="col" class="px-4">Closed at</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!tickets.length && !tableLoader">
                <td colspan="9">No results found</td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="9">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="ticket in tickets"
                :key="ticket.id"
                class=""
                @click.stop="openViewTicketDetailsModal(ticket)"
              >
                <td class="px-4">
                  <input
                    type="checkbox"
                    style="width: 15px; height: 15px"
                    @click.stop
                    :value="ticket"
                    :disabled="ticket.status === 'Closed'"
                    v-model="selected"
                  />
                </td>
                <th class="px-4">{{ ticket.id }}</th>
                <td class="px-4">{{ ticket.unit }}</td>
                <td class="px-4">{{ ticket.type }}</td>

                <td class="px-4">{{ ticket.creator_name }}</td>
                <td class="px-4">{{ ticket.created_at | completeDateTime }}</td>
                <td class="px-4">
                  <span
                    class="badge badge-pill"
                    :class="
                      ticket.status === 'Pending' ||
                      ticket.status === 'Forwarded to GSS'
                        ? 'badge-danger'
                        : 'badge-secondary'
                    "
                    >{{ ticket.status }}</span
                  >
                </td>
                <td class="px-4">
                  <span v-if="ticket.closed_at">{{
                    ticket.closed_at | completeDateTime
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>

    <ViewTicketDetailsModal
      ref="ViewTicketDetailsModal"
      @updateTable="
        getTickets(DEFAULT_URL, { loader: false, tableLoader: false })
      "
    ></ViewTicketDetailsModal>

    <CloseTicketModal
      ref="CloseTicketModal"
      :selected="selected"
      @updateTable="
        getTickets(DEFAULT_URL, { loader: false, tableLoader: false })
      "
    ></CloseTicketModal>
  </div>
</template>

<script>
import CreateTicketFormModal from "./components/CreateTicketFormModal.vue";
import ViewTicketDetailsModal from "./components/ViewTicketDetailsModal.vue";
import CloseTicketModal from "./components/CloseTicketModal.vue";

export default {
  name: "Main",
  components: {
    CreateTicketFormModal,
    ViewTicketDetailsModal,
    CloseTicketModal,
  },
  data() {
    return {
      tickets: [],
      loader: false,
      actionLoader: false,
      perPageOptions: [
        { id: 1, value: 10 },
        { id: 2, value: 25 },
        { id: 3, value: 50 },
        { id: 4, value: 100 },
      ],
      perPage: 10,
      searchValue: "",
      selected: [],
      DEFAULT_URL: "api/tickets",
      pagination: {},
      tableLoader: false,
      checked: false,
    };
  },
  methods: {
    getTickets(page_url, config) {
      page_url = page_url || this.DEFAULT_URL;

      this.loader = config.loader || false;
      this.tableLoader = config.tableLoader || false;

      axios
        .get(page_url, {
          params: {
            all: false,
            perPage: this.perPage,
          },
        })
        .then((res) => {
          this.makePagination(res.data);
          this.tickets = res.data.data;
        })
        .catch((err) => {
          toastr.error(err.response.data.message, "Server Error");
        })
        .finally(() => {
          this.loader = false;
          this.tableLoader = false;
        });
    },
    forwardJobOrderReferrals() {
      axios
        .post("api/support/tickets/to-job-order-referrals", {
          selected: this.selected,
        })
        .then((res) => {
          this.getTickets(this.DEFAULT_URL, {
            loader: false,
            tableLoader: false,
          });
          this.selected = [];
          this.checked = false;
        });
    },

    openViewTicketDetailsModal(ticket) {
      this.$refs.ViewTicketDetailsModal.open(ticket);
    },

    openCloseTicketModal(ticket) {
      this.$refs.CloseTicketModal.open(ticket);
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },

    selectAll() {
      this.selected = this.tickets.filter(
        (ticket) => ticket.status === "Pending"
      );
    },

    unselectAll() {
      this.selected = [];
    },
  },
  mounted() {
    this.getTickets(this.DEFAULT_URL, { loader: true, tableLoader: true });
  },
};
</script>

<style scoped>
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  left: 12px;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  pointer-events: none;
}
</style>
