<template>
  <div>
    <div id="close-ticket-modal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="closeTickets">
            <div class="modal-header">
              <h5 class="modal-title">Close ticket</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <ul class="list-group">
                  <li
                    v-for="ticket in selected"
                    :key="ticket.id"
                    class="list-group-item"
                  >
                    <strong>[Ticket No: {{ ticket.id }}]</strong> :
                    {{ ticket.type }}
                  </li>
                </ul>
              </div>

              <div class="form-group">
                <label for="">Feedback/Comment (Optional)</label>
                <textarea
                  v-model="feedback"
                  name=""
                  class="form-control"
                  placeholder="Feel free to provide your feedback or comment"
                  id=""
                  rows="5"
                  required
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                {{ isLoading ? "Submitting..." : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateTicketFormModal",
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      feedback: "",
      isLoading: false,
    };
  },
  methods: {
    open() {
      $("#close-ticket-modal").modal("show");
    },
    closeTickets() {
      this.isLoading = true;
      axios
        .post("api/support/tickets/close", {
          selected: this.selected,
          feedback: this.feedback,
        })
        .then((res) => {
          toastr.success(res.data.message, "Info");
          this.$emit("updateTable");
          $("#close-ticket-modal").modal("hide");
        })
        .catch((err) => {
          toastr.error(err.response.data.message, "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
