<template>
  <div>
    <div>
      <button @click="toggleModal" class="btn btn-primary">
        Create a new ticket
      </button>
    </div>

    <div
      id="create-ticket-form-modal"
      class="modal"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="submit">
            <div class="modal-header">
              <h5 class="modal-title">Create ticket</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="">Unit/Section</label>
                <select class="form-control" v-model="form.unit" required>
                  <option value="" selected disabled hidden>Choose</option>
                  <option
                    v-for="unit in units"
                    :key="unit.id"
                    :value="unit.name"
                  >
                    {{ unit.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="">Service Type</label>
                <select class="form-control" v-model="form.type" required>
                  <option value="" selected disabled hidden>Choose</option>
                  <option
                    v-for="type in types"
                    :key="type.id"
                    :value="type.name"
                  >
                    {{ type.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="">Description</label>
                <textarea
                  v-model="form.description"
                  name=""
                  class="form-control"
                  placeholder="Provide details about your issue or request, please be as descriptive as possible."
                  id=""
                  rows="5"
                  required
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                {{ isLoading ? "Submitting..." : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateTicketFormModal",
  data() {
    return {
      form: new Form({
        type: "",
        unit: "",
        assigned_to: "",
        description: "",
      }),
      isLoading: false,
      units: [
        { id: 1, name: "Accounting" },
        { id: 2, name: "Cashier" },
        { id: 3, name: "CMSP" },
        { id: 4, name: "General Services" },
        { id: 5, name: "Human Resources" },
        { id: 6, name: "Records" },
        { id: 7, name: "Technical" },
        { id: 8, name: "UniFAST" },
        { id: 9, name: "Admin" },
      ],
      types: [
        { id: 8, name: "Air Conditioning System" },
        { id: 9, name: "Building Structure" },
        { id: 5, name: "CCMIS System Glitch/Bug" },
        { id: 10, name: "Electrical" },
        { id: 3, name: "Hardware Installation" },
        { id: 4, name: "Maintenance" },
        { id: 11, name: "Plumbing" },
        { id: 1, name: "Troubleshooting" },
        { id: 2, name: "Software Installation" },
        { id: 7, name: "Repairs" },
        { id: 6, name: "Others" },
        { id: 12, name: "Data Request" },
      ],
    };
  },
  methods: {
    toggleModal() {
      $("#create-ticket-form-modal").modal("toggle");
    },
    submit() {
      this.isLoading = true;
      axios
        .post("api/tickets", this.form)
        .then((res) => {
          toastr.success(res.data.message, "Information");
          this.$emit("getTickets");
          this.toggleModal();
          this.form.reset();
        })
        .catch((error) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
