import Vue from "vue";
import Router from "vue-router";
import { canAccess } from "../middlewares/auth";
import store from "../store/store";
// Containers
const TheContainer = () => import("@/containers/TheContainer");
const TreesPublic = () => import("@/views/TreesPublic");
const Dashboard = () => import("@/views/Dashboard");
const Heis = () => import("@/views/Heis");
const Login = () => import("@/views/Login");
const HeiProfile = () => import("@/views/Heis/HeiProfile");
const Programs = () => import("@/views/Programs");
const Students = () => import("@/views/Students");
const DTR = () => import("@/views/HR/DTR.vue");
const AccomplishmentReport = () =>
  import("@/views/HR/AccomplishmentReport.vue");
const WeeklyPlan = () => import("@/views/HR/WeeklyPlan.vue");
const ARFormat = () => import("@/views/HR/components/ARFormat.vue");
const Biometric = () => import("@/views/HR/Biometric.vue");
const AddSchool = () => import("@/views/PlantingTrees/AddSchool.vue");
const PlantTrees = () => import("@/views/PlantingTrees/PlantTrees.vue");
const individualDTR = () => import("@/views/HR/components/DTRCSformat.vue");
const SpecialOrdersOld = () =>
  import("@/views/Records/SpecialOrdersOld/SpecialOrdersOld.vue");
const ProgramApplications = () => import("@/views/ProgramApplications");
const authorizePage = () => import("@/views/404.vue");
const redirectToEcav = () => import("@/views/RedirectToEcav");
const Cavs = () => import("@/views/Records/Cavs/Cavs");
const RecordsDashboard = () =>
  import("@/views/Records/Dashboard/RecordsDashboard");
const CavPayments = () => import("@/views/Records/Payments/Payments");
const Nstp = () => import("@/views/Nstp/Admin/Main");
const DTS = () => import("@/views/DTS/Main");
const SubmitProgramApplication = () =>
  import("@/views/SubmitProgramApplication");
const TosfApplicationForm = () =>
  import("@/views/TosfApplication/TosfApplicationForm");
const CavApplications = () => import("@/views/Records/CavApplications/Cavs");
const SmsNotificationCenter = () =>
  import("@/views/SmsNotificationCenter/Main.vue");
const CavStatusTracker = () =>
  import("@/views/CavStatusTracker/CavStatusTrackerMain.vue");

const RecognizedPrograms = () => import("@/views/RecognizedPrograms/Main.vue");
const ProgramDetails = () => import("@/views/ProgramDetails.vue");
const Calendar = () => import("@/views/Calendar.vue");
const Support = () => import("@/views/Support/Tickets/Main.vue");
const JobOrderReferrals = () =>
  import("@/views/Support/JobOrderReferrals/Main.vue");

Vue.use(Router);

export default new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "*",
      name: "404",
      component: authorizePage,
    },
    {
      path: "/redirect-to-ecav",
      name: "RedirectToEcav",
      component: redirectToEcav,
    },
    {
      path: "/cav-status-tracker",
      name: "CavStatusTracker",
      component: CavStatusTracker,
    },

    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/trees-public",
      name: "TreesPublic",
      component: TreesPublic,
    },
    {
      path: "/program-application",
      name: "SubmitProgramApplication",
      component: SubmitProgramApplication,
    },
    {
      path: "/tosf-application",
      name: "TosfApplication",
      component: TosfApplicationForm,
    },
    {
      path: "/dashboard",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
          beforeEnter(to, from, next) {
            canAccess("Dashboard", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/calendar",
          name: "Calendar",
          component: Calendar,
          beforeEnter(to, from, next) {
            canAccess("Calendar", store.state.user.role)
              ? next()
              : next("/404");
          },
        },

        {
          path: "/heis",
          name: "HEIs",
          component: Heis,
          beforeEnter(to, from, next) {
            canAccess("HEIs", store.state.user.role) ? next() : next("/404");
          },
        },
        {
          path: "/heis/:id",
          name: "HeiProfile",
          component: HeiProfile,
          props: true,
        },
        {
          path: "/programs/:id",
          name: "ProgramDetails",
          component: ProgramDetails,
          props: true,
        },
        {
          path: "/recognized-programs",
          name: "RecognizedPrograms",
          component: RecognizedPrograms,
          beforeEnter(to, from, next) {
            canAccess("RecognizedPrograms", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/programs",
          name: "Programs",
          component: Programs,
          beforeEnter(to, from, next) {
            canAccess("Programs", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/program-apps",
          name: "ProgramApplications",
          component: ProgramApplications,
          beforeEnter(to, from, next) {
            canAccess("ProgramApplications", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/accomplishment-report",
          name: "accomplishment_report",
          component: AccomplishmentReport,
          beforeEnter(to, from, next) {
            canAccess("accomplishment_report", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/weekly-plan",
          name: "weekly_plan",
          component: WeeklyPlan,
          beforeEnter(to, from, next) {
            canAccess("weekly_plan", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/hr",
          redirect: "/hr/dtr",
          name: "HR",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "dtr",
              name: "dtr",
              component: DTR,
              beforeEnter(to, from, next) {
                canAccess("dtr", store.state.user.role) ? next() : next("/404");
              },
            },
            {
              path: "dtr/:pID",
              name: "individualDTR",
              component: individualDTR,
              beforeEnter(to, from, next) {
                canAccess("individualDTR", store.state.user.role)
                  ? next()
                  : next("/404");
              },
            },

            {
              path: "individual-accomplishment-report/:pID",
              name: "individual_ar_format",
              component: ARFormat,
              beforeEnter(to, from, next) {
                canAccess("individual_ar_format", store.state.user.role)
                  ? next()
                  : next("/404");
              },
            },

            {
              path: "biometric",
              name: "biometric",
              component: Biometric,
              beforeEnter(to, from, next) {
                canAccess("biometric", store.state.user.role)
                  ? next()
                  : next("/404");
              },
            },
          ],
        },

        {
          path: "/records/dashboard",
          name: "RecordsDashboard",
          component: RecordsDashboard,
          beforeEnter(to, from, next) {
            canAccess("RecordsDashboard", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/records/students",
          name: "Students",
          component: Students,
          beforeEnter(to, from, next) {
            canAccess("Students", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/records/special-orders-old",
          name: "SpecialOrdersOld",
          component: SpecialOrdersOld,
          beforeEnter(to, from, next) {
            canAccess("SpecialOrdersOld", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/records/cav-applications",
          name: "CavApplications",
          component: CavApplications,
          beforeEnter(to, from, next) {
            canAccess("CavApplications", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/records/cavs",
          name: "Cavs",
          component: Cavs,
          beforeEnter(to, from, next) {
            canAccess("Cavs", store.state.user.role) ? next() : next("/404");
          },
        },
        {
          path: "/records/payments",
          name: "CavPayments",
          component: CavPayments,
          beforeEnter(to, from, next) {
            canAccess("CavPayments", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/planting-trees",
          redirect: "/planting-trees/plant",
          name: "planting_trees",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "plant",
              name: "plant",
              component: PlantTrees,
              beforeEnter(to, from, next) {
                canAccess("plant", store.state.user.role)
                  ? next()
                  : next("/404");
              },
            },
            {
              path: "add-school",
              name: "add_school",
              component: AddSchool,
              beforeEnter(to, from, next) {
                canAccess("add_school", store.state.user.role)
                  ? next()
                  : next("/404");
              },
            },
          ],
        },

        {
          path: "/admin/nstp",
          name: "Nstp",
          component: Nstp,
          beforeEnter(to, from, next) {
            canAccess("Nstp", store.state.user.role) ? next() : next("/404");
          },
        },

        {
          path: "/dts",
          name: "DTS",
          component: DTS,
          beforeEnter(to, from, next) {
            canAccess("DTS", store.state.user.role) ? next() : next("/404");
          },
        },
        {
          path: "/sms-notification-center",
          name: "SmsNotificationCenter",
          component: SmsNotificationCenter,
          beforeEnter(to, from, next) {
            canAccess("SmsNotificationCenter", store.state.user.role)
              ? next()
              : next("/404");
          },
        },
        {
          path: "/support/tickets",
          name: "Support",
          component: Support,
          beforeEnter(to, from, next) {
            canAccess("Support", store.state.user.role) ? next() : next("/404");
          },
        },
        {
          path: "/support/job-order-referrals",
          name: "JobOrderReferrals",
          component: JobOrderReferrals,
        },
      ],
    },
  ];
}
