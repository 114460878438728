<template>
  <CHeader fixed with-subheader light class="shadow-sm">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CBreadcrumbRouter class="border-0 mb-0" />
      <CHeaderNavItem class="px-3"> </CHeaderNavItem>
      <CHeaderNavItem class="px-3"> </CHeaderNavItem>
      <CHeaderNavItem class="px-3"> </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink> </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="mx-2">
        <CHeaderNavLink>
          <Notifications></Notifications>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <i class="bi bi-list-task"></i>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <i class="bi bi-envelope"></i>
        </CHeaderNavLink>
      </CHeaderNavItem class="d-md-down-none">
      <TheHeaderDropdownAccnt class="" />
      <div class="d-md-down-none">     
      <span
        v-if="!$store.state.user.role === 'planting_trees'"
        >{{
          getUser.user.first_name +
          " " +
          getUser.user.middle_name +
          " " +
          getUser.user.last_name
        }}</span
      >
      <span v-else>{{ getUserFullNameWithRole }}</span>
    </div>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import Notifications from "../components/Notifications.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TheHeader",
  data() {
    return {};
  },
  components: {
    TheHeaderDropdownAccnt,
    Notifications,
  },

  computed: {
    ...mapGetters(["getUser", "getUserFullNameWithRole"]),
  },
};
</script>
