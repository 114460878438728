<template>
  <div>
    <div
      id="main-dashboard"
      v-if="
        $store.state.user.role !== 'COS' &&
        $store.state.user.role !== 'Regular' &&
        $store.state.user.role !== 'admin' &&
        $store.state.user.role !== 'accounting' &&
        $store.state.user.role !== 'planting_trees' &&
        $store.state.user.role !== 'planting_trees_admin' &&
        $store.state.user.role !== 'HRMPSB' &&
        $store.state.user.role !== 'HR' &&
        $store.state.user.role !== 'supply_officer'
      "
    >
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="row">
            <div class="col-9 my-auto d-flex flex-row">
              <div class="my-auto">
                <h5 class="mb-1">
                  CHED Caraga Management Information System (CCMIS v1.0)
                </h5>
                <span class="text-muted">
                  View real-time data analytics from different Higher Education
                  Institution (HEI) in Caraga Region.
                </span>
              </div>
            </div>

            <div class="col-3">
              <div class="form-group mb-0">
                <label class="">Academic Year</label>
                <select
                  class="form-control"
                  v-model="academic_year"
                  id=""
                  @change="initDashboard"
                >
                  <option value="9">2023-2024</option>
                  <option value="8">2022-2023</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- WIDGETS -->

      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div
                class="px-3"
                style="border-left: 3px solid #6c757d !important"
              >
                <span class="">No. of HEIs</span>
                <div>
                  <h3 class="d-inline">
                    {{ num_heis[academic_year] }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div
                class="px-3"
                style="border-left: 3px solid #6c757d !important"
              >
                <span class="">No. of Enrolled Students</span>
                <div>
                  <h3 class="d-inline">
                    {{ num_students[academic_year] }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div
                class="px-3"
                style="border-left: 3px solid #6c757d !important"
              >
                <span class="">No. of Faculties</span>
                <div>
                  <h3 class="d-inline">
                    {{ num_faculties[academic_year] }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div
                class="px-3"
                style="border-left: 3px solid #6c757d !important"
              >
                <span class="">No. of Active Programs</span>
                <div>
                  <h3 class="d-inline">
                    {{ num_active_programs[academic_year] }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- // WIDGETS -->

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <!-- START - NUMBER OF TOTAL STUDENTS IN CARAGE REGION -->

          <div class="card shadow-sm">
            <div class="card-header">
              <strong
                >Total Number of Students in Caraga Region (Undergrad)</strong
              >
            </div>
            <div class="card-body overflow-auto" style="height: 400px">
              <canvas
                id="total-students-caraga-region-canvas"
                height="130px"
              ></canvas>
            </div>
          </div>

          <!-- END - NUMBER OF TOTAL STUDENTS IN CARAGE REGION -->
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <!-- START - NUMBER OF ENROLLMENT PER HEI -->

          <div>
            <div class="card shadow-sm">
              <div class="card-header">
                <b>Number of Enrollment per HEI </b>
              </div>
              <div class="card-body overflow-auto" style="height: 400px">
                <div id="loader1" class=""></div>

                <canvas
                  id="number-of-students-per-hei-canvas"
                  height="600"
                ></canvas>
              </div>
            </div>
          </div>

          <!-- END - NUMBER OF ENROLLMENT PER HEI -->
        </div>
      </div>

      <!-- START - NUMBER OF STUDENTS PER PROGRAM  -->

      <div class="card shadow-sm">
        <div class="card-header">
          <div class="row">
            <div class="col-8">
              <b>Number of Enrollees per Discipline</b>
            </div>

            <div class="col-4">
              <input
                v-model="searchValue"
                type="text"
                class="form-control"
                placeholder="Search program name"
              />
            </div>
          </div>
        </div>

        <div class="card-body overflow-auto shadow-sm" style="height: 500px">
          <div id="discipline-loader" class="my-3"></div>

          <table id="" style="" class="table table-sm">
            <thead class="">
              <th width="5%">#</th>
              <th width="20%">Program</th>
              <th width="20%">No. of Male Students</th>
              <th width="20%">No. Male Students</th>
              <th width="15%">Total # of Students</th>
            </thead>
            <tbody>
              <tr v-for="(record, index) in filteredDisciplines" :key="index">
                <th>{{ index + 1 }}</th>
                <td>{{ record.discipline }}</td>

                <td>
                  <div class="progress-group px-3">
                    <div class="progress-group-header">
                      <i class="bi bi-person icon-sm mr-1"></i>
                      <div>
                        Male - <b>{{ record.m }}</b>
                      </div>
                      <div class="mfs-auto font-weight-bold">
                        {{ record.m_percentage + "%" }}
                      </div>
                    </div>
                    <div class="progress-group-bars">
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          v-bind:style="{ width: record.m_percentage + '%' }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <div class="progress-group px-3">
                    <div class="progress-group-header">
                      <i class="bi bi-person icon-sm mr-1"></i>
                      <div>
                        Female - <b>{{ record.f }}</b>
                      </div>
                      <div class="mfs-auto font-weight-bold">
                        {{ record.f_percentage + "%" }}
                      </div>
                    </div>
                    <div class="progress-group-bars">
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{
                            width: record.f_percentage + '%',
                            backgroundColor: '#d45087',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <strong>{{ record.total }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- END - NUMBER OF STUDENTS PER PROGRAM -->

      <!-- START - NUMBER OF ENROLLMENT PER PROVINCE AND MUNICIPALITY -->

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="card shadow-sm">
            <div class="card-header">
              <b>Enrollment by Province and Gender</b>
            </div>
            <div class="card-body">
              <div id="loader2" class=""></div>
              <canvas id="enrollment-by-province-and-gender"></canvas>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="card shadow-sm">
            <div class="card-header">
              <span class="font-weight-bold"
                >Graduates by Discipline and Gender</span
              >
            </div>
            <div class="card-body">
              <div id="loader3"></div>
              <canvas
                id="graduates-by-discipline-and-gender"
                height="150px"
              ></canvas>
            </div>
          </div>
        </div>
      </div>

      <!-- END - NUMBER OF ENROLLMENT PER PROVINCE AND MUNICIPALITY -->
    </div>
    <div
      v-if="
        $store.state.user.role === 'planting_trees' ||
        $store.state.user.role === 'planting_trees_admin'
      "
    >
      <div
        class="embed-responsive embed-responsive-21by9"
        v-if="$store.state.user.role === 'planting_trees'"
      >
        <iframe
          src="https://drive.google.com/file/d/1Pd2cZMaibf0zfZ4RQNNo8i_XE7uMuEAJ/preview"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
      <trees-dashboard v-else></trees-dashboard>
    </div>
    <budget-dashboard
      v-if="
        $store.state.user.role === 'admin' ||
        $store.state.user.role === 'accounting'
      "
    ></budget-dashboard>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TreesDashboard from "./component/TreesDashboard.vue";
import BudgetDashboard from "./component/BudgetDashboard.vue";

export default {
  components: { TreesDashboard, BudgetDashboard },
  name: "Dashboard",
  data() {
    return {
      num_students: {
        8: 120900,
        9: 122740,
      },
      num_faculties: {
        8: 3373,
        9: 3185,
      },
      num_active_programs: {
        8: "No data",
        9: 863,
      },
      num_heis: {
        8: 56,
        9: 55,
      },
      currentUser: "",
      records: "",
      searchValue: "",
      academic_year: 9,
    };
  },
  methods: {
    initializeNumberOfStudentsPerHei() {
      var loader = document.getElementById("loader1");
      var c = document.getElementById("number-of-students-per-hei-canvas");

      loader.innerHTML = `<span class="spinner-border spinner-border-sm text-primary mr-2"></span> Retrieving data...`;

      axios
        .get("api/get-statistics", {
          params: {
            academic_year: this.academic_year,
          },
        })
        .then((response) => {
          var data = response.data;
          var totals = [],
            labels = [];

          // console.log(this.data);
          /* process data */

          data.forEach((i) => {
            labels.push(i.abbrev);
            totals.push(Number(i.Total));
          });

          var chart = new Chart(c, {
            plugins: [ChartDataLabels],
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "# of Students",
                  data: totals,
                  datalabels: {
                    color: "white",
                    font: { weight: "bold" },
                  },
                  backgroundColor: [
                    "#003f5c",
                    "#124060",
                    "#234263",
                    "#354367",
                    "#47456a",
                    "#58466e",
                    "#6a4871",
                    "#7c4975",
                    "#8d4a79",
                    "#9f4c7c",
                    "#b14d80",
                    "#c24f83",
                    "#d45087",
                  ],
                },
              ],
            },

            options: {
              indexAxis: "y",
              scales: {
                myScale: {
                  position: "top",
                  max: 10000,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            },
          });

          loader.remove();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initializeTotalEnrolleesChart() {
      var el = document.getElementById("total-students-caraga-region-canvas");

      var chart = new Chart(el, {
        type: "line",
        plugins: [ChartDataLabels],
        data: {
          labels: [
            "2009-10",
            "2013-14",
            "2014-15",
            "2017-18",
            "2018-19",
            "2019-20",
            "2020-21",
            "2021-22",
            "2022-23",
            "2023-24",
          ],
          datasets: [
            {
              label: "# of Students",
              data: [
                49343, 64168, 70646, 57055, 69758, 73422, 77465, 85030, 120900,
                122740,
              ],
              borderColor: "#d45087",
              backgroundColor: "#d45087",
              borderWidth: 3,
            },
            {
              label: "# of Graduates",
              data: [
                6550, 9124, 9877, 14092, 14632, 9087, 6136, 6381, 14946, 0,
              ],

              borderColor: "#003f5c",
              backgroundColor: "#003f5c",
              borderWidth: 3,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: "white",
              font: {
                weight: "bold",
              },
              padding: 6,
            },
          },
        },
        aspectRatio: 1,
      });
    },

    initializeEnrollmentByDiscipline() {
      let l = document.getElementById("discipline-loader");
      l.innerHTML = `<span class="spinner-border spinner-border-sm text-primary mr-2"></span> Retrieving data...`;

      axios
        .get("api/get-enrollees-per-discipline", {
          params: {
            academic_year: this.academic_year,
          },
        })
        .then((response) => {
          this.records = this.processData(response.data, l);
        })
        .catch((error) => {});
    },
    initializeEnrollmentByProvinceGender() {
      let e = document.getElementById("enrollment-by-province-and-gender");
      let l = document.getElementById("loader2");

      l.innerHTML = `<span class="spinner-border spinner-border-sm text-primary mr-2"></span> Retrieving data...`;

      axios
        .get("api/get-enrollees-by-province-and-gender", {
          params: {
            academic_year: this.academic_year,
          },
        })
        .then((response) => {
          let total_m = [],
            total_f = [],
            labels = [],
            results;

          results = response.data;

          results.forEach((data) => {
            labels.push(data.province);
            total_m.push(data.total_male);
            total_f.push(data.total_female);
          });

          let chart = new Chart(e, {
            type: "bar",
            /*  options: {
          responsive: true,
          plugins: {
            legend: 'top',
          },
        }, */
            plugins: [ChartDataLabels],
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Male",
                  data: total_m,
                  backgroundColor: "#003f5c",
                },
                {
                  label: "Female",
                  data: total_f,

                  backgroundColor: "#bc5090",
                },
              ],
            },
            options: {
              plugins: {
                datalabels: {
                  color: "white",
                  font: {
                    weight: "bold",
                  },
                },
              },
            },
          });

          l.remove();
        })
        .catch((error) => {});
    },
    initializeGraduateByDisciplineAndGender() {
      let l = document.getElementById("loader3");
      let el = document.getElementById("graduates-by-discipline-and-gender");

      l.innerHTML = `<span class="spinner-border spinner-border-sm text-primary mr-2"></span> Retrieving data...`;

      axios
        .get("api/get-graduates-by-discipline-and-gender", {
          params: {
            academic_year: this.academic_year,
          },
        })
        .then((res) => {
          /* process data */

          let results,
            labels = [],
            total_m = [],
            total_f = [];

          results = res.data;

          results.forEach((x) => {
            labels.push(x.description);
            total_m.push(x.total_male);
            total_f.push(x.total_female);
          });

          var c = new Chart(el, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Male",
                  data: total_m,
                  backgroundColor: ["#003f5c"],
                },

                {
                  label: "Female",
                  data: total_f,
                  backgroundColor: "#bc5090",
                },
              ],
            },

            options: {
              indexAxis: "y",
              scales: {
                y: {},
                x: {
                  stacked: "",
                },
                myScale: {
                  position: "top",
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
              },
            },
          });

          l.remove();
        })
        .catch((err) => {});
    },
    getUser() {
      axios.get("/api/user").then((response) => {
        this.currentUser = response.data;
      });
    },
    processData(records, loader) {
      let records_temp = records;

      records_temp.forEach((x) => {
        x.m_percentage = Math.round((x.m / x.total) * 100);
        x.f_percentage = Math.round((x.f / x.total) * 100);
      });

      loader.remove();

      return records_temp;
    },

    authCheck() {
      axios
        .get("api/check-auth")
        .then((response) => {
          if (!response.data) {
            this.$store.commit("setAuthentication", false);
            this.$store.commit("setUser", null);
            this.$router.push({ name: "Login" });
          }
        })
        .catch((error) => {});
    },

    hasIncomingDocuments() {
      axios
        .get("api/documents/incoming")
        .then((res) => {
          this.$store.commit(
            "setDocumentTrackingSidebarBadge",
            res.data.length ? true : false
          );
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
    hasSerialNumberRequests() {
      axios
        .get("api/nstp/admin/applications")
        .then((res) => {
          this.$store.commit(
            "setSerialNumberRequestsSidebarBadge",
            res.data.length ? true : false
          );
        })
        .catch((err) => {});
    },
    initDashboard() {
      this.initializeNumberOfStudentsPerHei();
      this.initializeEnrollmentByDiscipline();
      this.initializeTotalEnrolleesChart();
      this.initializeEnrollmentByProvinceGender();
      this.initializeGraduateByDisciplineAndGender();
    },
  },
  computed: {
    filteredDisciplines() {
      let temp_records = this.records;

      if (this.searchValue != "") {
        temp_records = temp_records.filter((data) => {
          return data.discipline
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp_records;
    },
  },
  mounted() {
    //this.authCheck();
    this.hasIncomingDocuments();
    this.hasSerialNumberRequests();
    this.initDashboard();
  },
};
</script>
